import type { FunctionComponent } from 'react'

import { Container } from '../../components/layout/grid'
import { Markdown } from '../../components/markdown'
import type { SimpleHeaderSectionFragment } from '../../graphql/fragments/SimpleHeaderSectionFragment.graphql'
import variables from '../../styles/variables.module.scss'
import { ActionButton } from '../action-button'
import styles from './simple-header-section.module.scss'

/**
 * Section that displays a heading, text.
 */

export const SimpleHeaderSection: FunctionComponent<Props> = ({
    heading,
    headingColor = 'white',
    subtitle,
    subtitleColor = 'white',
    anchorId = '',
    backgroundColor = 'blackV2',
    button,
    spacingTop = 'gapXXLarge',
    spacingBottom = 'gapXXLarge',
}) => {
    return (
        <div
            style={{
                backgroundColor: variables[backgroundColor],
                paddingTop: variables[spacingTop],
                paddingBottom: variables[spacingBottom],
            }}
            className={styles.ctaSection}
        >
            <Container id={anchorId}>
                <div>
                    {!!heading && (
                        <div className={styles.heading}>
                            <Markdown
                                tag='div'
                                typeStyle={{ _: 'heading-md' }}
                                markdown={heading}
                                color={headingColor}
                            />
                        </div>
                    )}
                    {!!subtitle && (
                        <div className={styles.subtitle}>
                            <Markdown
                                tag='p'
                                typeStyle={{ _: 'body-lg' }}
                                markdown={subtitle}
                                color={subtitleColor}
                            />
                        </div>
                    )}
                    {!!button && (
                        <ActionButton preset={button?.preset} {...button} />
                    )}
                </div>
            </Container>
        </div>
    )
}

export default SimpleHeaderSection

type Props = Omit<SimpleHeaderSectionFragment, 'sys' | 'type'> & {
    index?: number
}
